import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoLoginBoxComponent } from './auto-login-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WarningDeleteItemModule } from '@studentapp_shared/common-dialog/warning-delete-item/warning-delete-item.module';

@NgModule({
  declarations: [
    AutoLoginBoxComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    WarningDeleteItemModule,
  ],
  exports: [
    AutoLoginBoxComponent
  ]
})
export class AutoLoginBoxModule { }
