import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionDataManageService } from '@studentapp_core/services/DataService/session-data-manage.service';
import { LocalService } from '@studentapp_core/services/local.service';
import { ProductNavigationService } from '@studentapp_core/services/product-navigation.service';
import { UserDataService } from '@studentapp_core/services/user-data.service';
import { WarningModernViewModalComponent } from '@studentapp_shared/common-dialog/warning-modern-view-modal/warning-modern-view-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auto-login-box',
  templateUrl: './auto-login-box.component.html',
  styleUrls: ['./auto-login-box.component.scss']
})
export class AutoLoginBoxComponent implements OnInit, OnChanges, OnDestroy {

  @Input() accountDetail: any = null;
  @Input() triggerActionData: any = null;

  @Output() switchAccountEmitter = new EventEmitter();

  accountSetting: any = null;
  warningDialogRef: MatDialogRef<WarningModernViewModalComponent>;
  apiSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private localService: LocalService,
    private userDataService: UserDataService,
    private sessionDataManageService: SessionDataManageService,
    private productNavigationService: ProductNavigationService,
  ) { }

  // login detail containing email and username, either one is required for login
  async redirectToLoginPageToDoManualLoginRequest(_switch_account_response: any) {
    const _login_detail = _switch_account_response.login_detail;
    this.sessionDataManageService.doLogoutFromSystem(false);
    // ENABLED AUTO LOGIN
    this.localService.enableAutoLoginBySessionStorage();
    this.localService.storeAutoLoginSettingInSessionStorage(this.accountSetting);
    // store login detail in storage
    this.localService.storeUserInputAtRegistration(_login_detail);
    this.switchAccountEmitter.emit({ ..._switch_account_response });
    // const _state_url = this.router.url;
    // this.productNavigationService.redirectToSigninModalWithFallbackRoute(_state_url, { redirect_to: _state_url, id: null }, { action: 'REFRESH_CURRENT_DATASET' }, 'student/login', false);
    await this.router.navigate(['', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve' });
  }

  async doAutomaticallyLoginAfterSuccessFulSwitchRequest(_switch_account_response: any) {
    const _login_detail = _switch_account_response.login_detail;
    this.sessionDataManageService.doLogoutFromSystem(false);
    this.localService.disbleAutoLoginBySessionStorage();
    this.localService.removeUserInputAtRegistration();
    this.localService.removeAutoLoginSettingInSessionStorage();
    // ENABLED DIRECT LOGIN
    this.localService.enableDirectLoginBySessionStorage();
    // store DIRECT login detail in storage
    this.localService.storeDirectLoggedInformationAsSwitchFeature(_login_detail);
    this.switchAccountEmitter.emit({ ..._switch_account_response });
    // const _state_url = this.router.url;
    // this.productNavigationService.redirectToSigninModalWithFallbackRoute(_state_url, { redirect_to: _state_url, id: null }, { action: 'REFRESH_CURRENT_DATASET' }, 'student/login', false);
    await this.router.navigate(['', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve' });
  }

  doSwitchAccount(_switch_account_response: any) {
    if (_switch_account_response.action === 'logout_from_current_auto_new_login') {
      this.doAutomaticallyLoginAfterSuccessFulSwitchRequest(_switch_account_response);
    }
    else if (_switch_account_response.action === 'logout_from_current_manual_login') {
      this.redirectToLoginPageToDoManualLoginRequest(_switch_account_response);
    }
  }

  // Method to try to switch automatically to another account
  tryToSwitchAutomaticallyInOtherAccount(_account_detail: any, switch_action_triggered_by: 'DEFAULT' | 'AUTOMATIC' | 'FROM_LOGIN_FLOW' = 'DEFAULT') {
    // If the targeted account is a parent account, always ask for a password
    if (_account_detail.process.is_parent) {
      // Set the selected account control value to the selected account ID
      this.doSwitchAccount({ status: 'success', switch_action_triggered_by: switch_action_triggered_by, action: 'logout_from_current_manual_login', account_id: _account_detail.id, login_detail: { username: _account_detail.username, email: _account_detail.email } });
      return;
    }
    // Prepare payload for switching account
    const _payload_body = {
      username: _account_detail.username,
    };
    // Call the service to switch to another account
    this.apiSubscription = this.userDataService.switchToAnotherAccount(_payload_body).subscribe(
      (response: any) => {
        // On success, set the selected account control value and close the dialog with specific status and action
        this.doSwitchAccount({ status: 'success', switch_action_triggered_by: switch_action_triggered_by, action: 'logout_from_current_auto_new_login', account_id: _account_detail.id, login_detail: response });
      },
      (error: any) => {
        // On error, if the switch action was triggered by default, set the selected account control value and close the dialog with specific status and action
        if (switch_action_triggered_by === 'DEFAULT') {
          this.doSwitchAccount({ status: 'success', switch_action_triggered_by: switch_action_triggered_by, action: 'logout_from_current_manual_login', account_id: _account_detail.id, login_detail: { username: _account_detail.username, email: _account_detail.email } });
        }
      }
    )
  }

  // Method to show a warning dialog before switching to a different account
  showWarningDialogBeforeSwitchDifferentAccount(_account_detail: any) {
    const _current_user_id = this.localService.getUserIdLocalStorage();
    if (_current_user_id == _account_detail.id) {
      return;
    }
    // Get the account name to display in the warning message
    const _account_name = _account_detail.process.students[0].nick_name ? _account_detail.process.students[0].nick_name : _account_detail.process.students[0].name ? _account_detail.process.students[0].name : _account_detail.username;
    // Open the warning dialog
    this.warningDialogRef = this.dialog.open(
      WarningModernViewModalComponent,
      {
        height: 'auto',
        width: '450px',
        data: {
          index: -1,
          modalInfo: { accountInfo: _account_detail },
          headingText: 'Switch Account',
          mainDescription: `You are about to switch to <span class="font-weight-bold">${_account_name}</span>'s account.`,
          subDescription: `This action will log you out of your current session. You may need to enter the password for the new account.`,
          confirmBtnText: 'Switch Account',
        },
        panelClass: 'warning-msg-modal'
      }
    );

    // Subscribe to the afterClosed event of the warning dialog
    this.subscriptions.push(
      this.warningDialogRef.afterClosed().subscribe(
        (response: any) => {
          // If the response indicates user confirms, try to switch automatically to the other account
          if (response && response.actionMode === 'DELETE') {
            this.tryToSwitchAutomaticallyInOtherAccount(_account_detail);
          }
          else {
            this.switchAccountEmitter.emit({ status: 'success', action: 'stay_as_current_user_logged_in' });
          }
        }
      )
    )
  }

  // Method to get the list of linked accounts for the logged-in user
  getLinkedAccountsListForLoggedInUser() {
    // Call the service to get the list of linked account users
    this.apiSubscription = this.userDataService.getLinkedAccountUsersList().subscribe(
      (response: any) => {
        // Filter the response to get only student accounts (excluding parent accounts)
        const _student_accounts_list = response.filter((_link_account) => !_link_account.process.is_parent);
        
        // If there is exactly one student account, try to switch automatically to that account
        if (_student_accounts_list.length === 1) {
          this.tryToSwitchAutomaticallyInOtherAccount(_student_accounts_list[0], 'FROM_LOGIN_FLOW');
        }
        // If there are no student accounts, emit an event indicating no student accounts were found
        else if (_student_accounts_list.length === 0) {
          this.switchAccountEmitter.emit({ status: 'success', action: 'no_students_account_found' });
        }
        // If there is more than one student account, emit an event indicating multiple student accounts were found
        else {
          this.switchAccountEmitter.emit({ status: 'success', action: 'more_than_one_students_account_found' });
        }
      },
      // Handle any errors that occur during the API call
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Method to handle account detail switch actions
  handleAccountDetailSwitchAction(_action_detail: any) {
    // Extract account detail from the action detail
    const _account_detail = _action_detail.account_detail;
    
    // If account setting is provided in the action detail, update the component's account setting
    if (_action_detail.account_setting) {
      this.accountSetting = _action_detail.account_setting;
    }
    
    // If the action detail indicates to show a warning before switching accounts
    if (_action_detail.show_warning_before_switch) {
      // If account detail is available, show the warning dialog
      if (_account_detail) {
        this.showWarningDialogBeforeSwitchDifferentAccount(_account_detail);
      }
    }
    // If the action detail indicates to do an automatic switch of accounts
    else if (_action_detail.do_automatic_switch_account) {
      // If account detail is available, try to switch automatically to the other account
      if (_account_detail) {
        this.tryToSwitchAutomaticallyInOtherAccount(_account_detail, 'AUTOMATIC');
      }
    }
    // If the action detail indicates to check linked accounts and switch
    else if (_action_detail.do_check_linked_account_and_switch) {
      // Get the list of linked accounts for the logged-in user
      this.getLinkedAccountsListForLoggedInUser();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHANGES', changes);
    if (changes['triggerActionData'] && changes['triggerActionData'].isFirstChange() === false) {
      if (changes['triggerActionData'].currentValue) {
        this.handleAccountDetailSwitchAction(changes['triggerActionData'].currentValue);
      }
    }
  }

  ngOnInit(): void {
    if (this.triggerActionData) {
      this.handleAccountDetailSwitchAction(this.triggerActionData);
    }
  }

  ngOnDestroy(): void {
    this.apiSubscription?.unsubscribe();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
