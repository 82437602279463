import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authenticationCompleted = new BehaviorSubject<any>(null);
  authenticationCompleted$ = this.authenticationCompleted.asObservable();

  constructor() { }

  isAuthProcessCompleted(status: any) {
    this.authenticationCompleted.next({ is_authenticated: status });
  }

  resetAuthProcessCompleted() {
    this.authenticationCompleted.next(null);
  }
}
