<div class="warning-modal-c detailed-warning-view">
    <div class="title-container">
        <svg [ngClass]="headingIconClass" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/>
            <line x1="12" y1="9" x2="12" y2="13"/>
            <line x1="12" y1="17" x2="12.01" y2="17"/>
        </svg>
        <h2 class="warning-title">{{ headingText }}</h2>
    </div>
    
    <p class="warning-description" *ngIf="mainDescription" [innerHTML]="mainDescription"></p>

    <p class="warning-description warning-secondary-text" *ngIf="subDescription" [innerHTML]="subDescription"></p>

    <div class="actions d-flex justify-content-end w-100">
        <button class="btn btn-sm btn-outline-secondary" mat-dialog-close>{{ cancelBtnText }}</button>
        <button class="btn btn-sm btn-purple" *ngIf="isConfirmBtnVisible" (click)="doAction()">{{ confirmBtnText }}</button>
    </div>
</div>