import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-modern-view-modal',
  templateUrl: './warning-modern-view-modal.component.html',
  styleUrls: ['./warning-modern-view-modal.component.scss']
})
export class WarningModernViewModalComponent implements OnInit {

  info: any = null;
  topicInfo = null;
  itemIndex: number;
  msg = '';
  btnText = '';
  
  headingIcon = '';
  headingIconClass = '';
  headingText = '';

  mainDescription = '';
  subDescription = '';

  confirmBtnText = '';
  cancelBtnText = '';
  isConfirmBtnVisible = true;

  constructor(
    private dialogRef: MatDialogRef<WarningModernViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.itemIndex = this.data.index;
    this.info = this.data.modalInfo ? this.data.modalInfo : null;
    this.topicInfo = this.data.topicInfo ? this.data.topicInfo : null;

    this.headingIcon = this.data.icon ? this.data.icon : 'assets/images/svg/error.svg';
    this.headingIconClass = this.data.iconClass ? this.data.iconClass : 'warning-icon';
    this.headingText = this.data.headingText ? this.data.headingText : 'Delete Item';

    this.mainDescription = this.data.mainDescription ? this.data.mainDescription : 'Delete Item';
    this.subDescription = this.data.subDescription ? this.data.subDescription : 'Delete Item';

    this.confirmBtnText = this.data.confirmBtnText ? this.data.confirmBtnText : 'Confirm';
    this.cancelBtnText = this.data.cancelBtnText ? this.data.cancelBtnText : 'Cancel';
    this.isConfirmBtnVisible = 'isConfirmBtnVisible' in this.data ? this.data.isConfirmBtnVisible : true;
  }

  doAction() {
    this.dialogRef.close({ actionMode: 'DELETE', index: this.itemIndex, detail: this.info, topicInfo: this.topicInfo });
  }

  ngOnInit(): void {
  }

}
