import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DataService } from '@studentapp_core/services/DataService/data.service';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';
import { LocalService } from '@studentapp_core/services/local.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-switcher-bottom-sheet',
  templateUrl: './product-switcher-bottom-sheet.component.html',
  styleUrls: ['./product-switcher-bottom-sheet.component.scss']
})
export class ProductSwitcherBottomSheetComponent implements OnInit, OnDestroy {

  productDataListArray = [];
  currentActiveProductType = '';
  activeBranchInfo = null;
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
    private _bottomSheetRef: MatBottomSheetRef<ProductSwitcherBottomSheetComponent>,
    private localService: LocalService,
    private dataService: DataService,
    private branchProductService: BranchProductService,
  ) {
    this.activeBranchInfo = this.localService.getCurrentDoerCampusBranchInfo();
    this.listenForBranchProductRelatedChanges();
  }

  openLink(event: MouseEvent): void {
    this.dataService.updateStudentSelection([]);
    this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }


  listenForBranchProductRelatedChanges() {
    this.subscriptions.push(
      this.branchProductService.currentLiveCourseProduct$.subscribe(
        (response: any) => {
          if (response && response.product_setting) {
            this.currentActiveProductType = response.product_setting.product_type;
          }
        }
      )
    );

    this.subscriptions.push(
      this.branchProductService.availableProductsList$.subscribe(
        (response: any) => {
          if (response && response.length) {
            this.productDataListArray = response;
          }
        }
      )
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
