import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Topic } from '../../../content/work-sheet/work-sheet/work-sheet-list/work-sheet-list.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  lecture_content$: Observable<any>;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private BASE_URL = environment.base_url;

  constructor(private http: HttpClient) {
  }

  /* check an object is empty or not */
  isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }


  /* set the one lecture content data at a time */
  setLectureContentData(LectureContentData) {
    console.log({ LectureContentData });
    sessionStorage.removeItem('LectureContentData');
    sessionStorage.setItem('LectureContentData', JSON.stringify(LectureContentData));
  }

  /* get the one lecture content data at a time */
  getLectureContentData() {
    if (sessionStorage.getItem('LectureContentData')) {
      return JSON.parse(sessionStorage.getItem('LectureContentData'));
    }
    return null;
  }

  /* set selected student's data */
  setCurrentStudentData(CurrentStudent) {
    localStorage.setItem('CurrentStudent', JSON.stringify(CurrentStudent));
  }

  /* get currently logged in student information */
  getCurrentStudentData() {
    if (localStorage.getItem('CurrentStudent')) {
      return JSON.parse(localStorage.getItem('CurrentStudent'));
    }
    return null;
  }

  getCurrentStudentId() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      return _student_info.id;
    }
    return 0;
  }

  getFirstOrganizationStudentId() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      if (_student_info && _student_info.orgs_info && _student_info.orgs_info.length)
      return _student_info.orgs_info[0].org_std_id;
    }
    return 0;
  }

  getCurrentStudentDataWithFallback() {
    if (localStorage.getItem('CurrentStudent')) {
      return JSON.parse(localStorage.getItem('CurrentStudent'));
    }
    else if (localStorage.getItem('studentInfo')) {
      return JSON.parse(localStorage.getItem('studentInfo'));
    }
    return null;
  }

  getCurrentStudentIdWithFallback() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      return _student_info.id;
    }
    else if (localStorage.getItem('studentInfo')) {
      const _student_info = JSON.parse(localStorage.getItem('studentInfo'));
      return _student_info.id;
    }
    return 0;
  }

  getCurrentUserDataWithFallback() {
    if (localStorage.getItem('CurrentUser')) {
      return JSON.parse(localStorage.getItem('CurrentUser')).user;
    }
    else if (localStorage.getItem('accountInfo')) {
      return JSON.parse(localStorage.getItem('accountInfo'));
    }
    return null;
  }

  /* remove current student's data */
  removeCurrentStudentData() {
    localStorage.removeItem('CurrentStudent');
  }

  setWorksheetModeSetting(setting: any) {
    sessionStorage.setItem('worksheet_mode_setting', JSON.stringify(setting));
  }

  getWorksheetModeSetting() {
    if (sessionStorage.getItem('worksheet_mode_setting')) {
      return JSON.parse(sessionStorage.getItem('worksheet_mode_setting'));
    }
    return null;
  }

  removeWorksheetModeSetting() {
    sessionStorage.removeItem('worksheet_mode_setting');
  }

  /* set list or array of main-topic-ids for the work-sheet purpose */
  setMainTopicIDs(mainTopicIDs: Array<Topic>) {
    sessionStorage.setItem('main-topic-ids', JSON.stringify(mainTopicIDs));
  }

  /* get list or array of main-topic-ids for the work-sheet purpose */
  getMainTopicIDs(): Array<Topic> {
    if (sessionStorage.getItem('main-topic-ids')) {
      return JSON.parse(sessionStorage.getItem('main-topic-ids'));
    }
    return [];
  }

  /* remove list or array of main-topic-ids */
  removeMainTopicIDs() {
    sessionStorage.removeItem('main-topic-ids');
  }


  /* set mock exam log-id */
  setMockExamLogID(id) {
    sessionStorage.setItem('mock-exam-log-id', JSON.stringify(id));
  }

  /* get mock exam log-id */
  getMockExamLogID() {
    if (sessionStorage.getItem('mock-exam-log-id')) {
      return JSON.parse(sessionStorage.getItem('mock-exam-log-id'));
    }
    return null;
  }

  /* remove mock exam log-id */
  removeMockExamLogID() {
    sessionStorage.removeItem('mock-exam-log-id');
  }



  /* set work-sheet log-id */
  setWorkSheetLogID(id) {
    sessionStorage.setItem('work-sheet-log-id', JSON.stringify(id));
  }

  /* get work-sheet log-id */
  getWorkSheetLogID() {
    if (sessionStorage.getItem('work-sheet-log-id')) {
      return JSON.parse(sessionStorage.getItem('work-sheet-log-id'));
    }
    return null;
  }

  /* remove work-sheet log-id */
  removeWorkSheetLogID() {
    sessionStorage.removeItem('work-sheet-log-id');
  }

  /* set selected student's data */
  setCurrentUserData(CurrentUser) {
    localStorage.setItem('CurrentUser', JSON.stringify(CurrentUser));
  }

  /* get currently logged in user's all information */
  getCurrentUser() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser'));
    else
      return null;
  }

  /* get currently logged in user's information */
  getCurrentUserData() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser')).user;
    else
      return null;
  }

  /* get currently logged in user's information */
  getCurrentUserId() {
    if (localStorage.getItem('CurrentUser')) {
      const _user_info = JSON.parse(localStorage.getItem('CurrentUser')).user;
      if (_user_info) {
        return _user_info.id;
      }
    }
    return 0;
  }

  /* remove current user's data */
  removeCurrentUserData() {
    localStorage.removeItem('CurrentUser');
  }


  /* get current student subscription */
  getCurrentStudentSubscription() {
    if (this.getCurrentStudentData()) {
      const currentStudentData = this.getCurrentStudentData();
      if (currentStudentData.student_subscription && !this.isEmpty(currentStudentData.student_subscription))
        return currentStudentData.student_subscription;
      else
        return null;
    }
    return null;
  }

  /* check for account management page access (authentication purpose) */
  isUserAuthenticated() {
    if (sessionStorage.getItem('isUserAuthenticated')) {
      return true;
    } else {
      return false;
    }
  }

  /* set for account management page access (authentication purpose) */
  set_UserAuthenticated() {
    sessionStorage.setItem('isUserAuthenticated', JSON.stringify(true));
  }

  /* remove for account management page access (authentication purpose) */
  removeUserAuthenticated() {
    if (this.isUserAuthenticated()) {
      sessionStorage.removeItem('isUserAuthenticated');
    }
  }

  /* get all the super-book */
  getSuperBooks(data) {
    if (this.getCurrentStudentData()) {
      data = Object.assign({}, data, { userpublickey: this.getCurrentStudentData().id });
    } else {
      data = Object.assign({}, data, { userpublickey: null });
    }
    return this.http.post(`${this.BASE_URL}/student/book/`, data, { headers: this.headers });
  }

  /* GET all main-topic/sub-topic/lecture etc */
  getTableOfContent(subject_id: any, student_id: number) {
    let $OBJ = {
      student_id: student_id,
      subject_id: subject_id
    }
    return this.http.post(`${this.BASE_URL}/student/content/`, $OBJ, { headers: this.headers });
  }


  /* get only selected GET all main-topic/sub-topic/lecture etc */
  getSelectedContent(maintopic_id: number, student_id: number, subject_id: number) {
    let $OBJ = {
      student_id: student_id,
      maintopic_id: maintopic_id,
      subject_id: subject_id
    }
    return this.http.post(`${this.BASE_URL}/student/content/list/`, $OBJ, { headers: this.headers });
  }


  /* get the selected lecture content data (video/text-content/test/quiz-bank components etc) 
     if isClicked === false then API will be called again otherwise not
  */
  getLectureContent(lecture_id: number, isClicked: boolean, subtopic_id: number, maintopic_id: number, subject_id: number) {
    if (!isClicked) {

      let $OBJ = {
        student_id: this.getCurrentStudentData().id,
        subject_id: subject_id,
        maintopic_id: maintopic_id,
        subtopic_id: subtopic_id,
        lecture_id: lecture_id
      }

      return this.http.post(`${this.BASE_URL}/student/course/`, $OBJ, { headers: this.headers });
      // this.lecture_content$ = this.http.post(`${this.BASE_URL}/student/course/`, $OBJ, { headers: this.headers })
      //   .pipe(
      //     tap(() => console.log("/student/course/ API called")),
      //     map(lecture_content => {
      //       return lecture_content
      //     }),
      //     shareReplay()
      //   );
    }

  }



  /* get all the question-folders json as one array for performing the quiz */
  getAllQuestionFolders(quiz_bank_id) {
    let $OBJ = {
      quiz_bankid: quiz_bank_id
    }
    return this.http.post(`${this.BASE_URL}/student/course/quiz/`, $OBJ, { headers: this.headers });
  }

  /* get all the question-folders json as one array for performing the quiz */
  getAllTextContentpages(student_id, text_content_id) {
    let $OBJ = {
      student_id: student_id,
      text_id: text_content_id
    }
    return this.http.post(`${this.BASE_URL}/student/course/text/`, $OBJ, { headers: this.headers });
  }




  /* when user move from one lecture componet to another lecture component 
     then we send the nessery information to backend to update the course progress 
  */
  updateCourseProgress(student_id, LectureContentData) {
    let $OBJ = {
      student_id: student_id,
      comp_type: LectureContentData.type,
      comp_id: LectureContentData.id,
      comp_status: true,
    }
    return this.http.post(`${this.BASE_URL}/student/course/log/`, $OBJ, { headers: this.headers });
  }

  /* Get all onine work sheet data */
  getonlineworksheetdata(student_id, maintopicid, selected_grade_id, selected_subject_id, selected_subject_category_id) {
    let $OBJ = {
      student_id: student_id,
      maintopic_ids: maintopicid,
      grade_id: selected_grade_id,
      subject_category_id: selected_subject_category_id,
      subject_id: selected_subject_id
    }
    console.log($OBJ);
    return this.http.post(`${this.BASE_URL}/start-online-worksheet/`, $OBJ, { headers: this.headers });
  }

  /* Get data online work sheet data  */
  getonlineworksheetdatafortesting() {

    return this.http.post(`${this.BASE_URL}/test-online-worksheet/`, { headers: this.headers });

  }
  // submit the online worksheet

  submitonlineworksheet(obj) {

    return this.http.post(`${this.BASE_URL}/update-online-worksheet/`, obj, { headers: this.headers });
  }
  // submit the online worksheet

  getWorksheetDataById(obj) {

    return this.http.post(`${this.BASE_URL}/student-review-worksheet/`, obj, { headers: this.headers });
  }




  /* get all grades and subjects from the DB */
  getGradeSubjectList() {
    let student_id = this.getCurrentStudentData() ? this.getCurrentStudentData().id : '';
    return this.http.get(`${this.BASE_URL}/student/subject-grade/list/?student_id=${student_id}`);
  }

  getSkillTopicsWithAssociateSkills(skill_book_id) {
    return this.http.get(`${this.BASE_URL}/list/skill-topics-tree/${skill_book_id}`, { headers: this.headers });
  }

  /* get all grades and subjects from the DB */
  getGradeSubjectModifiedList(params: any) {
    return this.http.get(`${this.BASE_URL}/student/subjects-grades/list/`, { params });
  }

  /* get all grades and subjects from the DB */
  getGradeSubjectSkillsList(object: any) {
    let student_id = this.getCurrentStudentData() ? this.getCurrentStudentData().id : '';
    return this.http.post(`${this.BASE_URL}/student/subject-grade-skill/list/?student_id=${student_id}`, object);
  }

  getLearnSectionsList(data) {
    return this.http.post(`${this.BASE_URL}/student/lp_courses/`, data, { headers: this.headers });
  }

  getLearningPathsList(data) {
    return this.http.post(`${this.BASE_URL}/student/get_lp_data/`, data, { headers: this.headers });
  }

  getCoursesList(data) {
    return this.http.post(`${this.BASE_URL}/student/get_sb_data/`, data, { headers: this.headers });
  }

  getLearningPathTableOfContent(obj: any) {
    return this.http.post(`${this.BASE_URL}/lp/content/`, obj, { headers: this.headers });
  }

  getCourseTableOfContent(subject_id: any, student_id: number) {
    let $OBJ = {
      student_id: student_id,
      subject_id: subject_id
    }
    return this.http.post(`${this.BASE_URL}/student/sb_content/`, $OBJ, { headers: this.headers });
  }

  /*  */
  getcheck() {
    return this.http.get(`${this.BASE_URL}/retrive-chargebee-plans/`);
  }

  /* update loggedin user's data to the DB */
  updateUserInfo(data: any) {
    return this.http.put(`${this.BASE_URL}/register/user-details/`, data, { headers: this.headers });
  }

  /* update loggedin user's students data to the DB */
  updateStudentInfo(data: any) {
    return this.http.put(`${this.BASE_URL}/register/student-details/`, data, { headers: this.headers });
  }

  /* update loggedin user's students data to the DB or add new students */
  // addOrUpdateStudentInfo(data: any) {
  //   return this.http.post(`${this.BASE_URL}/add-new-student/`, data, { headers: this.headers });
  // }
  addOrUpdateStudentInfo(data: any) {
    return this.http.post(`${this.BASE_URL}/add_new_student/`, data, { headers: this.headers });
  }

  /* change password for the user */
  changePassword(data: object) {
    return this.http.post(`${this.BASE_URL}/register/user-change-password/`, data, { headers: this.headers });
  }


  /* cancel subscription for a active student */
  cancelSubscription(data) {
    return this.http.post(`${this.BASE_URL}/user/cancel-student-subscription-pay/`, data, { headers: this.headers });
  }

  /* reactivate subscription for a non-renewing student */
  reactivateSubscription(data) {
    return this.http.post(`${this.BASE_URL}/user/reactivate-student-subscription-pay/`, data, { headers: this.headers });
  }




  /* log apis section */

  /* log api for each lecture components like (TEXT/VIDEO/QUIZ etc...) */
  lectureComponentLogs(data: object) {
    console.log({ data });
    // return of('TEST LECTURE COMPONENT LOG API');
    return this.http.post(`${this.BASE_URL}/student-course-log/`, data, { headers: this.headers });
  }

  /* log api to store each question-folders log info for [QUIZ] */
  questionFolderLogs(data: object) {
    console.log({ data });
    // return of('TEST QUESTION FOLDER LOG API');
    return this.http.post(`${this.BASE_URL}/student-question-log/`, data, { headers: this.headers });
  }

  /* log api to store each question-folders log info for [WORKSHEET] */
  onlineWorkSheetLogs(data: object) {
    console.log({ data });
    return this.http.post(`${this.BASE_URL}/student-worksheet-log/`, data, { headers: this.headers });
  }

  /* log api for entire test */
  testLogs(data: object) {
    // return this.http.post(`${this.BASE_URL}/student-test-log/`, data, { headers: this.headers });
    return this.http.post(`${this.BASE_URL}/update-student-test/`, data, { headers: this.headers });
  }

  /* log api for entire offline mock test only */
  testLogsOffline(data: object) {
    return this.http.post(`${this.BASE_URL}/update-student-offline-test/`, data, { headers: this.headers });
  }

  /* log api for entire offline work-sheet only */
  workSheetLogsOffline(data: object) {
    return this.http.post(`${this.BASE_URL}/update-offline-worksheet/`, data, { headers: this.headers });
  }


  /* log api for all question-folders */
  testQFLogsMock(data: object) {
    return this.http.post(`${this.BASE_URL}/store-mock-exam-log/`, data, { headers: this.headers });
  }

  /* log api for all question-folders (for offline mock exam purpose only) */
  testQFLogsMockOffline(data: object) {
    return this.http.post(`${this.BASE_URL}/store-offline-exam-log/`, data, { headers: this.headers });
  }

  /* log api for all question-folders */
  testQFLogsAssessment(data: object) {
    return this.http.post(`${this.BASE_URL}/store-assessment-log/`, data, { headers: this.headers });
  }




  /* report section */
  getIssues(data) {
    return this.http.post(`${this.BASE_URL}/list-issues/`, data, { headers: this.headers });
  }

  uploadReportInfo(data) {
    return this.http.post(`${this.BASE_URL}/store-student-panel-issues/`, data, { headers: this.headers });
  }


  /* get all the question-folders json as one array for performing the quiz */
  getTestModuleData(student_id, test_id) {
    let $OBJ = {
      student_id: student_id,
      test_id: test_id
    }
    return this.http.post(`${this.BASE_URL}/student-course-test/`, $OBJ, { headers: this.headers });
  }

  /* get all the question-folders json as one array for performing the mock-test (ONLINE) */
  getMockExamData(student_id, id, status) {
    if (status === 'submit') {
      let $OBJ = {
        student_id: student_id,
        mock_exam_id: id
      }
      return this.http.post(`${this.BASE_URL}/start-new-mock-exam/`, $OBJ, { headers: this.headers });
    } else {
      let $OBJ = {
        student_id: student_id,
        log_id: id
      }
      return this.http.post(`${this.BASE_URL}/student-online-exam-log/`, $OBJ, { headers: this.headers });
    }
  }

  /* get all the log json for a particular the mock-test based on log-id */
  getMockExamLogData(student_id, log_id) {
    let $OBJ = {
      student_id: student_id,
      log_id: log_id
    }
    return this.http.post(`${this.BASE_URL}/student-mock-exam-log/`, $OBJ, { headers: this.headers });
  }

  /* get all the pdf + question-folders json as one array for performing the mock-test (OFFLINE)*/
  getMockExamOfflineData(student_id, id, status) {
    if (status === 'submit') {
      let $OBJ = {
        student_id: student_id,
        mock_exam_id: id
      }
      return this.http.post(`${this.BASE_URL}/start-offline-exam/`, $OBJ, { headers: this.headers });
    } else if (status === 're-submit') {
      let $OBJ = {
        student_id: student_id,
        log_id: id
      }
      return this.http.post(`${this.BASE_URL}/student-offline-exam-log/`, $OBJ, { headers: this.headers });
    }
  }

  /* get all the pdf + question-folders json as one array for performing the wroksheet (OFFLINE)*/
  generateOfflineWorkSheetData(student_id, subject_id, subject_category_id, mainTopicIDs, grade_id, status) {
    let $OBJ = {
      grade_id: grade_id,
      subject_category_id: subject_category_id,
      subject_id: subject_id,
      student_id: student_id,
      maintopic_ids: mainTopicIDs,
    }
    return this.http.post(`${this.BASE_URL}/start-offline-worksheet/`, $OBJ, { headers: this.headers });
  }

  getOfflineWorksheetLogData(student_id, log_id, status) {
    let $OBJ = {
      student_id: student_id,
      log_id: log_id
    }
    return this.http.post(`${this.BASE_URL}/student-offline-worksheet-log/`, $OBJ, { headers: this.headers });
  }

  /* get all the log json for a particular the work-sheet based on log-id */
  getWorkSheetLogData(student_id, log_id) {
    let $OBJ = {
      student_id: student_id,
      log_id: log_id
    }
    return this.http.post(`${this.BASE_URL}/student-review-worksheet/`, $OBJ, { headers: this.headers });
  }

  /* get all the text as json */
  getMockExamTEXTData(test_id) {
    let $OBJ = {
      test_id: test_id
    }
    return this.http.post(`${this.BASE_URL}/print-text-content/`, $OBJ, { headers: this.headers });
  }

  /* get the question-folder's json */
  getMockExamQFData(question_id) {
    let $OBJ = {
      question_id: question_id,
    }
    return this.http.post(`${this.BASE_URL}/print-question-folder/`, $OBJ, { headers: this.headers });
  }


  /* for student credit deduction */
  checkStudentCredits($OBJ) {
    return this.http.post(`${this.BASE_URL}/check-student-credits/`, $OBJ, { headers: this.headers });
  }

  /* get all the question-folders json as one array for performing the assessment */
  getAssessmentTestData(student_id, exam_id) {
    let $OBJ = {
      student_id: student_id,
      assessment_id: exam_id
    }
    return this.http.post(`${this.BASE_URL}/start-new-assessment/`, $OBJ, { headers: this.headers });
  }



  /* get grade and mock exam type */
  getGradeMocknameList(data: any) {
    return this.http.post(`${this.BASE_URL}/student-grade-exam-list/`, data, { headers: this.headers });
  }

  /* get list of mock exams */
  getMockExamsData(student_id, param) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-mock-exams/`, $OBJ, { params: param });
  }

  /* get list of mock exams type */
  getMockExamsType(student_id, grade_id) {
    let $OBJ = {
      student_id: student_id,
      grade_id: grade_id
    }
    return this.http.post(`${this.BASE_URL}/student-mock-exam-list/`, $OBJ, { headers: this.headers });
  }


  /* get all grades and subjects from the DB for assessment */
  getGradeSubject(student_id) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-assessment-grade-subject/`, $OBJ, { headers: this.headers });
  }

  /* get list of assessments */
  getAssessmentData(student_id, param) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-assessment-list/`, $OBJ, { params: param });
  }

  /* get list of assessments score percentage */
  getLogPercentageData(student_id, param) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-assessment-score/`, $OBJ, { params: param });
  }



  /* get all grades and subjects from the DB for work-sheet */
  getWorkSheetGradeSubject(data: any) {
    return this.http.post(`${this.BASE_URL}/student-worksheet-grade-subject/`, data, { headers: this.headers });
  }

  /* get list of work-sheets */
  getWorkSheetData(student_id, param) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-worksheets/`, $OBJ, { params: param });
  }

  /* log api for all question-folders (for offline work-sheet purpose only) */
  testQFLogsWorkSheetOffline(data: object) {
    return this.http.post(`${this.BASE_URL}/store-worksheet-log/`, data, { headers: this.headers });
  }

  /* get list of topics and it's score percentage */
  getTopicPercentageData(student_id, param) {
    let $OBJ = {
      student_id: student_id
    }
    return this.http.post(`${this.BASE_URL}/student-worksheet-score/`, $OBJ, { params: param });
  }




  /* ASK-DOUBT section */

  /* add a new doubt by the student */
  addNewDoubt(OBJ) {
    return this.http.post(`${this.BASE_URL}/add-new-doubt/`, OBJ, { headers: this.headers });
  }

  /* get list of doubts ask by a specific student */
  getDoubtsList(OBJ) {
    return this.http.post(`${this.BASE_URL}/list-doubt-message/`, OBJ, { headers: this.headers });
  }

  /* get list of chats ask by a specific student against  a specific doubt */
  getChatHistory(OBJ) {
    return this.http.post(`${this.BASE_URL}/list-message/`, OBJ, { headers: this.headers });
  }

  /* send a message by a specific student against a specific doubt */
  sendMessage(OBJ) {
    return this.http.post(`${this.BASE_URL}/add-new-message/`, OBJ, { headers: this.headers });
  }

  /* update a message by a specific student against a specific doubt */
  updateMessage(OBJ, msg_id) {
    return this.http.patch(`${this.BASE_URL}/update-message/${msg_id}`, OBJ, { headers: this.headers });
  }

  /* once any doubt is clear */
  markedAsDoubtIsClear(id) {
    return this.http.patch(`${this.BASE_URL}/update-doubt/${id}`, { headers: this.headers });
  }


  /* check for new or unread messages count for a all doubts */
  getAllDoubtsUnreadMessageCount(OBJ) {
    return this.http.post(`${this.BASE_URL}/get-all-unread-message/`, OBJ, { headers: this.headers });
  }



  /* FEATURE-SUPPORT section */

  /* add a new ticket by the student */
  addNewTicket(OBJ) {
    return this.http.post(`${this.BASE_URL}/add-new-ticket/`, OBJ, { headers: this.headers });
  }

  /* get list of tickets generated by a specific student */
  getTicketsList(OBJ) {
    return this.http.post(`${this.BASE_URL}/list-tickets/`, OBJ, { headers: this.headers });
  }

  /* get list of conversations ask by a specific student against a specific ticket */
  getConversationsHistory(OBJ) {
    return this.http.post(`${this.BASE_URL}/list-of-communication/`, OBJ, { headers: this.headers });
  }

  /* send a new conversation by a specific student against a specific ticket */
  sendNewConversation(OBJ) {
    return this.http.post(`${this.BASE_URL}/add-new-communication/`, OBJ, { headers: this.headers });
  }

  /* update an existing conversation by a specific student against a specific ticket */
  updateConversation(OBJ, conversation_id) {
    return this.http.patch(`${this.BASE_URL}/update-communication/${conversation_id}`, OBJ, { headers: this.headers });
  }

  /* once any ticket has been closed */
  markedAsTicketIsClear(id) {
    return this.http.patch(`${this.BASE_URL}/update-ticket/${id}`, { headers: this.headers });
  }

  /* check for new or unread conversations count for a all tickets */
  getAllTicketsUnreadConversationCount(OBJ) {
    return this.http.post(`${this.BASE_URL}/get-all-unread-conversation/`, OBJ, { headers: this.headers });
  }

  fetchLiveClassesListForStudents(studentId: any) {
    return this.http.get(`${this.BASE_URL}/list_live_classes/?studentid=${studentId}`);
  }

  fetchSpecificClassDetails(classId: number) {
    return this.http.get(`${this.BASE_URL}/get_live_class/${classId}`);
  }

  generateRTMTokenByUidFromServer(data: any) {
    return this.http.post(`${this.BASE_URL}/get_rtm_details`, data);
  }

  generateRTCTokenByUidFromServer(data: any) {
    return this.http.post(`${this.BASE_URL}/student_valid_or_not`, data);
  }

  saveClassMessage(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/class_messages`, OBJ, { headers: this.headers });
  }

  getClassMessagesList(classid: any) {
    return this.http.get(`${this.BASE_URL}/class_messages?classid=${classid}`);
  }

  updateClassAttendance(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/add_attendence?`, OBJ);
  }

  updateOnlineClassLogs(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/live_class_action`, OBJ);
  }

  fetchSpecificClassWhiteboardRoomDetails(OBJ: any) {
    return this.http.get(`${this.BASE_URL}/create_board_room`, { params: OBJ });
  }

  generateWhiteboardTokenFromServer(data: any) {
    return this.http.post(`${this.BASE_URL}/create_board_room`, data);
  }

  addWhiteboardParticipant(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/board_participants`, OBJ);
  }

  removeWhiteboardParticipant(params: any) {
    return this.http.delete(`${this.BASE_URL}/board_participants`, { params });
  }
  
  updateWhiteboardParticipantPermission(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/update_board_write`, OBJ);
  }

  fetchSpecificWhiteboardRoomDetails(params: any) {
    return this.http.get(`${this.BASE_URL}/board_details`, { params });
  }

  renewWhiteboardRoomToken(OBJ: any) {
    return this.http.post(`${this.BASE_URL}/board_renew_token`, OBJ);
  }

}

