import { Injectable } from '@angular/core';
import { DataService } from './DataService/data.service';
import { LocalService } from './local.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@studentapp_env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  env = environment;
  private BASE_URL = environment.base_url;

  // BehaviorSubject to track the status of fetching campus branches
  doerCampusBranchesFetchStatus = new BehaviorSubject<any>({ status: 'ON_INIT' });
  // Observable for the fetch status
  doerCampusBranchesFetchStatus$ = this.doerCampusBranchesFetchStatus.asObservable();
  // Method to update the fetch status
  updateDoerCampusBranchesFetchStatusInfo(statusName: any) {
    this.doerCampusBranchesFetchStatus.next(statusName);
  }

  // BehaviorSubject to trigger account selection action
  triggerAccountSelectionAction = new BehaviorSubject<any>(null);
  // Observable for the account selection action
  triggerAccountSelectionAction$ = this.triggerAccountSelectionAction.asObservable();
  // Method to update the account selection action
  updateTriggerAccountSelectionAction(action_info: any) {
    this.triggerAccountSelectionAction.next(action_info);
  }

  // Constructor to inject necessary services
  constructor(
    private http: HttpClient, // Service to make HTTP requests
    private dataService: DataService, // Service to handle data operations
    private localService: LocalService, // Service to handle local storage operations
  ) { }

  // Method to set the branch IDs in the environment configuration
  setDoerCampusBranchIdsInEnvironment(_branch_id: number) {
    this.env.productsConfig.doerCampus.link_branch_ids = [_branch_id];
  }

  // Method to clear the branch IDs in the environment configuration
  clearDoerCampusBranchIdsInEnvironment() {
    this.env.productsConfig.doerCampus.link_branch_ids = [];
  }

  // Method to update the current user and student details
  updateCurrentUserAndStudentDetails(_user_info: any) {
    // Get the current user from local storage
    let CurrentUser = this.localService.getCurrentUser();
    // Get student data from given user info
    let _students_data = _user_info && _user_info.student_data ? _user_info.student_data : [];
    // Update the current user's student data
    CurrentUser.process.students = _students_data;
    // Save the updated user data to local storage
    this.localService.setCurrentUserData(CurrentUser);
    // Update the current student details
    this.updateCurrentStudentDetails(_students_data);
    // Update the storage listener triggered
    this.dataService.update_storage(true);
  }

  // Method to update the current student details
  updateCurrentStudentDetails(_students_data) {
    // Get the current student ID from local storage
    let CurrentStudentId = this.localService.getCurrentStudentId();
    // Find the current student data
    const currentStudentData = _students_data.find((_stu_info: any) => _stu_info.id === CurrentStudentId);
    if (currentStudentData)  {
      // Update the current doer campus branch detail
      this.updateCurrentDoerCampusBranchDetail(currentStudentData);
      // Save the current student data to local storage
      this.localService.setCurrentStudentData(currentStudentData);
    }
  }

  // Method to update the current doer campus branch detail
  updateCurrentDoerCampusBranchDetail(currentStudentData: any) {
    // Get the current doer campus branch info from local storage
    const CurrentDoerSchoolBranchInfo = this.localService.getCurrentDoerCampusBranchInfo();
    let _new_doer_school_branch_info = null;
    if (currentStudentData.branch_data && currentStudentData.branch_data.length) {
      if (CurrentDoerSchoolBranchInfo) {
        // Find the active branch
        const _active_branch_matched = currentStudentData.branch_data.find((_each_branch) => _each_branch.id === CurrentDoerSchoolBranchInfo.id);
        if (!_active_branch_matched) {
          // Set the new branch info to the first branch if no active branch is matched
          _new_doer_school_branch_info = currentStudentData.branch_data[0];
        }
        else {
          // Set the new branch info to the matched active branch
          _new_doer_school_branch_info = _active_branch_matched;
        }
      }
      else {
        // Set the new branch info to the first branch if no current branch info is found
        _new_doer_school_branch_info = currentStudentData.branch_data[0];
      }
    }
    if (_new_doer_school_branch_info) {
      // Save the new branch info to local storage
      this.localService.setCurrentDoerCampusBranchInfo(_new_doer_school_branch_info);
      // Set the branch IDs in the environment configuration
      this.setDoerCampusBranchIdsInEnvironment(_new_doer_school_branch_info.id);
    }
    else {
      // Remove the current branch info from local storage
      this.localService.removeCurrentDoerCampusBranchInfo();
      // Clear the branch IDs in the environment configuration
      this.clearDoerCampusBranchIdsInEnvironment();
    }
  }

  // Method to get the logged-in user's data from the database
  getUserDetails(user_id) {
    let OBJ = {
      account_id: user_id
    }
    // Make a POST request to get user details
    return this.http.post(`${this.BASE_URL}/register/user-details/`, OBJ);
  }

  // Method to update student information in storage
  updateStudentInformationInStorage() {
    // Update student user details
    this.updateStudentUserDetails();
  }

  // Method to update student data in local storage every time the account management popup is closed
  updateStudentUserDetails() {
    // Get the user ID from local storage
    let user_id = this.localService.getUserIdLocalStorage();
    // Return if no user ID is found
    if (!user_id) return;
    // Make a request to get user details
    this.getUserDetails(user_id).subscribe(
      (data: any) => {
        if (!data.isError) {
          // Update the current user and student details if no error is found
          this.updateCurrentUserAndStudentDetails(data.accountInfo);
        }
      },
      // Log the error if the request fails
      (error) => console.error(error)
    );
  }

  // Method to get the list of linked account users
  getLinkedAccountUsersList() {
    // Make a GET request to get the list of linked account users
    return this.http.get(`${this.BASE_URL}/get_linked_accounts_list`);
  }
  
  // Method to switch to another account
  switchToAnotherAccount(data: any) {
    // Make a POST request to switch to another account
    return this.http.post(`${this.BASE_URL}/student_switching`, data);
  }
}
