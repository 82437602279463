import { Injectable } from '@angular/core';
import { LocalService } from '../local.service';
import { AuthService } from '../APIService/auth.service';
import { DataService } from './data.service';
import { SegmentAnalyticsService } from '../segment-analytics.serrvice';
import { ReferalSourceDetectService } from '../referal-source-detect.service';
import { AuthenticationService } from '../authentication.service';
import { SocketRealtimeCommunicationService } from '../_socket_services/socket-realtime-communication.service';
import { ProductNavigationService } from '../product-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class SessionDataManageService {

  constructor(
    private localService: LocalService,
    private authService: AuthService,
    private dataService: DataService,
    private segmentService: SegmentAnalyticsService,
    private referSourceDetectService: ReferalSourceDetectService,
    private authenticationService: AuthenticationService,
    private socketRealTImeCommService: SocketRealtimeCommunicationService,
    private productNavigationService: ProductNavigationService,
  ) { }

  
  async doLogoutFromSystem(_navigate_home_page = true) {
    localStorage.removeItem('CurrentUser');
    localStorage.removeItem('selected_grade_id');
    localStorage.removeItem('selected_subject_id');
    
    this.localService.removeTemporaryAccountInfoBeforeLogin();
    this.localService.removeUserInputAtRegistration();
    this.localService.removeTemporaryUserInfoAfterRegistration();
    this.localService.removeTemporaryStudentInfoAfterStudentRegistration();

    this.authService.removeCurrentStudentData();
    this.dataService.update_storage(true);
    this.dataService.updateStudentSelection([]);
    this.segmentService.reset();
    this.referSourceDetectService.removeReferalSourceValueFromSessionStorage();
    this.localService.removeCurrentDoerCampusBranchInfo();
    this.authenticationService.isAuthProcessCompleted(false);
    // this.socketRealTImeCommService.disconnectSocketConnection();
    // if (_navigate_home_page) {
    //   return await this.productNavigationService.stayOnSamePageByClearingModalOutlet({ }, { action: 'REFRESH_CURRENT_DATASET' });
    // }
  }
}
