import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PRODUCT_ROUTE_PATHS, SKIP_ALL_KIND_RESOLVER_API_CALL } from '@studentapp_core/common/constant';
import { checkRouteBelongsToAuthGuard } from '@studentapp_core/common/basic-routing.module';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class ProductNavigationService {

  productRoutePathSetting = PRODUCT_ROUTE_PATHS;
  defaultFallbackPage = `/${this.productRoutePathSetting.doerDo}/learn/home`;
  resolverSkipObjectData = SKIP_ALL_KIND_RESOLVER_API_CALL;

  constructor(
    private router: Router,
    private localService: LocalService,
  ) { }

  /**
    Navigates back to the last visited page, or to a default page if no previous page was visited.
    @param fallbackRoutePath The route path to navigate to if no previous page was visited.
    @param isReload Whether to reload the page after navigating.
  */
  async goBackToLastVisitedPageElseFallback(fallbackRoutePath = this.defaultFallbackPage, isReload = false) {
    const tempPath = this.localService.getLastVisitedLearnPageUrl();
    await this.router.navigateByUrl(tempPath ? tempPath : fallbackRoutePath);
    if (isReload) {
      location.reload();
    }
  }

  async navigateToDefaultHomePage(fallbackRoutePath = this.defaultFallbackPage, queryParams: any = { refer_source: null }, isReload = true) {
    await this.router.navigate(
      [``, { outlets: { modal: null }}],
      {
        queryParamsHandling: 'preserve',
      }
    );
    await this.router.navigate(
      [
        `${ fallbackRoutePath }`,
      ],
      {
        queryParams,
        queryParamsHandling: 'merge',
      }
    );
    if (isReload) {
      location.reload();
    }
  }

  async redirectToSigninModalWithFallbackRoute(_current_url: any, queryParams: any, stateValue: any = null, signinPath: string = 'student/login', isReload = false) {
    const _is_fallback = checkRouteBelongsToAuthGuard(_current_url);
    if (_is_fallback) {
      await this.router.navigate(
        [``],
        {
          queryParams,
          queryParamsHandling: 'merge',
        }
      );
    }
    delete queryParams['redirect_to'];
    await this.router.navigate(['', { outlets: { modal: signinPath } }], { state: {...stateValue}, queryParams, queryParamsHandling: 'merge'});
    if (isReload) {
      location.reload();
    }
  }

  async redirectToPageByURL(redirectUrl: string, state: any = null, isReload = false) {
    await this.router.navigateByUrl(redirectUrl, {
      state: { ...this.resolverSkipObjectData, ...state },
    });
    if (isReload) {
      location.reload();
    }
  }

  async stayOnSamePageByClearingModalOutlet(queryParams: any = null, state: any = null, isReload = false) {
    await this.router.navigate(['', { outlets: { modal: null }}], {
      queryParams,
      state: { ...this.resolverSkipObjectData, ...state },
      queryParamsHandling: 'merge',
    });
    if (isReload) {
      location.reload();
    }
  }

  async navigateToOnlineCLassPage(_class_id: number, subRoutePath = this.productRoutePathSetting.doerDo) {
    await this.router.navigate(
      [ subRoutePath, 'online-class' ],
      {
        queryParams: {
          cid: _class_id,
          show_page: 'LIVE_CLASS',
          mode: 'start'
        }
      }
    );
  }
}
