import { Routes } from "@angular/router";
import { AuthGuard } from "@studentapp_core/guards/auth.guard";
import { MenuConfigLoaderResolver } from "@studentapp_core/resolvers/menu-config-loader/menu-config-loader.resolver";
import { BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE, TOP_MENU_FLAG_VALUE } from "./constant";

export const LEARN_PAGE_DEFAULT_ROUTE: Routes = [
    {
        path: 'learn',
        loadChildren: () => import('@studentapp_pages/learn-section-layout/learn-section-layout.module').then(m => m.LearnSectionLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            // pageType: 'LEARN',
        }
    },
];


export const SUPERBOOK_DETAIL_DEFAULT_ROUTE: Routes = [
    {
        path: 'superbooks',
        loadChildren: () => import('@studentapp_pages/subject/subject.module').then(m => m.SubjectModule), 
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [],
            },
            pageType: 'SUPERBOOK',
        }
    },
];

export const MOCK_EXAMS_DEFAULT_ROUTE: Routes = [
    {
        path: 'mock-exams',
        loadChildren: () => import('@studentapp_pages/mock-exam/mock-exam.module').then(m => m.MockExamModule),
        // data: {
        //   pageType: 'MOCK_EXAM',
        // }
    },
];

export const WORK_SHEET_DEFAULT_ROUTE: Routes = [
    {
        path: 'work-sheet',
        loadChildren: () => import('@studentapp_pages/work-sheet/work-sheet.module').then(m => m.WorkSheetModule),
        // data: {
        //   pageType: 'WORK_SHEET',
        // }
    },
];

export const ASK_DOUBT_DEFAULT_ROUTE: Routes = [
    {
        path: 'ask-doubt',
        loadChildren: () => import('@studentapp_pages/ask-doubt/ask-doubt.module').then(m => m.AskDoubtModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [ TOP_MENU_FLAG_VALUE, BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE ],
            },
            // pageType: 'ASK_DOUBT',
        }
    },
];

export const LIVE_COURSE_DEFAULT_ROUTE: Routes = [
    {
        path: 'live-courses',
        loadChildren: () => import('@studentapp_pages/live-courses-section-layout/live-courses-section-layout.module').then(m => m.LiveCoursesSectionLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'LIVE_COURSE',
        }
    },
];

export const MY_COURSE_DEFAULT_ROUTE: Routes = [
    {
        path: 'my-courses',
        loadChildren: () => import('@studentapp_pages/live-my-courses-section-layout/live-my-courses-section-layout.module').then(m => m.LiveMyCoursesSectionLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'LIVE_MY_COURSE',
        }
    },
];

export const MY_ENROLLED_COURSE_DETAIL_DEFAULT_ROUTE: Routes = [
    {
        path: 'my-courses/enrolled-course/:course_id',
        loadChildren: () => import('@studentapp_pages/dg-live-course-enrolled-section-layout/dg-live-course-enrolled-section-layout.module').then(m => m.DgLiveCourseEnrolledSectionLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'LIVE_ENROLLED_COURSE',
        },
        canActivate: [AuthGuard],
    },
];

export const LIVE_COURSE_DETAIL_DEFAULT_ROUTE: Routes = [
    {
        path: 'live-courses/course-preview/:course_id',
        loadChildren: () => import('@studentapp_pages/dd-live-course-preview-layout/dd-live-course-preview-layout.module').then(m => m.DdLiveCoursePreviewLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [],
            },
            pageType: 'LIVE_PUBLIC_COURSE',
        },
    },
];

export const ONLINE_LIVE_CLASS_DEFAULT_ROUTE: Routes = [
    {
        path: 'online-class',
        loadChildren: () => import('@studentapp_pages/online-class-page/online-class-page.module').then(m => m.OnlineClassPageModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [],
            },
            pageType: 'ONLINE_CLASSROOM',
        },
        canActivate: [AuthGuard]
    },
];

export const LIVE_CALENDAR_DEFAULT_ROUTE: Routes = [
    {
        path: 'my-calendar',
        loadChildren: () => import('@studentapp_pages/section-live-calendar-layout/section-live-calendar-layout.module').then(m => m.SectionLiveCalendarLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'CALENDAR',
        },
        canActivate: [AuthGuard]
      },
];

export const ANNOUNCEMENT_PAGE_DEFAULT_ROUTE: Routes = [
    {
        path: 'announcements',
        loadChildren: () => import('@studentapp_pages/simple-notifications-layout/simple-notifications-layout.module').then(m => m.SimpleNotificationsLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'ANNOUCEMENTS',
        },
        canActivate: [AuthGuard]
    },
];

export const CHANNEL_COMMUNICATION_PAGE_DEFAULT_ROUTE: Routes = [
    {
        path: 'channel-communications',
        loadChildren: () => import('@studentapp_pages/channel-communications-layout/channel-communications-layout.module').then(m => m.ChannelCommunicationsLayoutModule),
        resolve: {
            menuGlobalConfig: MenuConfigLoaderResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
            pageConfig: {
                visibleMenus: [TOP_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE],
            },
            pageType: 'CHANNEL_COMMUNICATION',
        },
        canActivate: [AuthGuard]
    },
];

export const AUTH_GUARD_ACTIVE_ROUTES = [
    'my-calendar',
    'announcements',
    'channel-communications',
    'online-class',
    'enrolled-course',
]

export const checkRouteBelongsToAuthGuard = (route: string): boolean => {
    const _is_route_found = AUTH_GUARD_ACTIVE_ROUTES.find((activeRoute) => route.indexOf(activeRoute) >= 0);
    if (_is_route_found) {
        const _is_redircting_route = (route.indexOf('redirect_to=') >= 0) ? true : false;
        return _is_redircting_route ? false : true;
    }
    return _is_route_found ? true : false;
}