import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authenticationCompleted = new BehaviorSubject<boolean>(false);
  authenticationCompleted$ = this.authenticationCompleted.asObservable();

  constructor() { }

  isAuthProcessCompleted(status: any) {
    this.authenticationCompleted.next(status);
  }
}
