import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@studentapp_core/services/APIService/auth.service';
import { LocalService } from '@studentapp_core/services/local.service';
import { UserDataService } from '@studentapp_core/services/user-data.service';
import { environment } from '@studentapp_env/environment';
import { WarningDeleteItemComponent } from '@studentapp_shared/common-dialog/warning-delete-item/warning-delete-item.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-selection-dialog',
  templateUrl: './account-selection-dialog.component.html',
  styleUrls: ['./account-selection-dialog.component.scss']
})
export class AccountSelectionDialogComponent implements OnInit, OnDestroy {

  env = environment;
  imagePath = this.env.cdn_urls.image;

  dataLoading = true;
  linkedAccountsList: any[] = [];
  parentAccountsList: any[] = [];
  studentAccountsList: any[] = [];
  // isDisabled: Boolean = false;

  popupTriggeredBy: 'DEFAULT' | 'AUTOMATIC' = 'DEFAULT';
  currentUserData: any = null;
  currentStudentData: any = null;
  selectedAccountCtrl = new FormControl();

  accountSwitchActionObj: any = null;

  warningDialogRef: MatDialogRef<WarningDeleteItemComponent>;
  apiSubscription: Subscription = null;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router, // Router service to navigate between routes
    private dialog: MatDialog, // Service to handle dialog operations
    private localService: LocalService, // Service to handle local storage operations
    private userDataService: UserDataService, // Service to handle user data operations
    public authService: AuthService, // Service to handle authentication operations
    public dialogRef: MatDialogRef<AccountSelectionDialogComponent>, // Reference to the dialog
    @Inject(MAT_DIALOG_DATA) private data: any, // Data passed to the dialog
  ) {
    // Get current user data from local storage
    this.currentUserData = this.localService.getCurrentUserData();
    // Get current student data from local storage
    this.currentStudentData = this.localService.getCurrentStudentData();
    // Set the selected account control value to the current user ID
    this.selectedAccountCtrl.setValue(this.currentUserData.id);
    // Determine what triggered the popup, default to 'DEFAULT' if not specified
    this.popupTriggeredBy = 'triggered_by' in this.data ? this.data.triggered_by : 'DEFAULT';
  }

  // Method to close the dialog
  closeDialog() {
    // If the popup was triggered automatically, close the dialog with a specific status and action
    if (this.popupTriggeredBy === 'AUTOMATIC') {
      location.reload();
      return this.dialogRef.close({ status: 'success', action: 'close_popup_with_reload' });
    }
    // Otherwise, just close the dialog
    return this.dialogRef.close();
  }

  // Method to add a new student
  addNewStudent() {
    // Close the current dialog
    this.dialogRef.close();
    // Navigate to the add student route
    this.router.navigate(['', { outlets: { modal: 'student/addStudent' }}]);
  }

  handleAccountSwitchEventHandler(event: any) {
    if (event.status === 'success') {
      if (event.action === 'logout_from_current_manual_login' || event.action === 'logout_from_current_auto_new_login') {
        this.selectedAccountCtrl.setValue(event.account_id);
        this.dialogRef.close({ ...event });
      }
      else if (event.action === 'trigger_action_data_reset') {
        this.accountSwitchActionObj = null;    
      }
    }
  }

  triggerSwitchAccountEvent(_account_detail: any) {
    if (this.currentUserData.id == _account_detail.id) {
      return;
    }
    this.accountSwitchActionObj = { show_warning_before_switch: true, account_detail: _account_detail, account_setting: { AL_trigger_from: 'AC_SELECTION' } };
    return;
  }

  // // Method to try to switch automatically to another account
  // tryToSwitchAutomaticallyInOtherAccount(_account_detail: any, switch_action_triggered_by: 'DEFAULT' | 'AUTOMATIC' = 'DEFAULT') {
  //   // If the targeted account is a parent account, always ask for a password
  //   if (_account_detail.process.is_parent) {
  //     // Set the selected account control value to the selected account ID
  //     this.selectedAccountCtrl.setValue(_account_detail.id);
  //     // Close the dialog with specific status and action, and pass login details
  //     this.dialogRef.close({ status: 'success', action: 'logout_from_current_manual_login', login_detail: { username: _account_detail.username, email: _account_detail.email } });
  //     return;
  //   }
  //   // Prepare payload for switching account
  //   const _payload_body = {
  //     username: _account_detail.username,
  //   };
  //   // Call the service to switch to another account
  //   this.apiSubscription = this.userDataService.switchToAnotherAccount(_payload_body).subscribe(
  //     (response: any) => {
  //       // On success, set the selected account control value and close the dialog with specific status and action
  //       this.selectedAccountCtrl.setValue(_account_detail.id);
  //       this.dialogRef.close({ status: 'success', action: 'logout_from_current_auto_new_login', login_detail: response })
  //     },
  //     (error: any) => {
  //       // On error, if the switch action was triggered by default, set the selected account control value and close the dialog with specific status and action
  //       if (switch_action_triggered_by === 'DEFAULT') {
  //         this.selectedAccountCtrl.setValue(_account_detail.id);
  //         this.dialogRef.close({ status: 'success', action: 'logout_from_current_manual_login', login_detail: { username: _account_detail.username, email: _account_detail.email } })
  //       }
  //     }
  //   )
  // }

  // // Method to show a warning dialog before switching to a different account
  // showWarningDialogBeforeSwitchDifferentAccount(_account_detail: any) {
  //   if (this.currentUserData.id == _account_detail.id) {
  //     return;
  //   }
  //   // Get the account name to display in the warning message
  //   const _account_name = _account_detail.process.students[0].nick_name ? _account_detail.process.students[0].nick_name : _account_detail.process.students[0].name ? _account_detail.process.students[0].name : _account_detail.username;
  //   // Open the warning dialog
  //   this.warningDialogRef = this.dialog.open(
  //     WarningDeleteItemComponent,
  //     {
  //       height: 'auto',
  //       width: '600px',
  //       data: {
  //         index: -1,
  //         modalInfo: { accountInfo: _account_detail },
  //         displayMsg: `Are you sure want to login into ${_account_name}'s account. By confirming this you will be logout from current account, also you may need to give password for this account?`,
  //       },
  //       panelClass: 'warning-msg-modal'
  //     }
  //   );

  //   // Subscribe to the afterClosed event of the warning dialog
  //   this.subscriptions.push(
  //     this.warningDialogRef.afterClosed().subscribe(
  //       (response: any) => {
  //         // If the response indicates user confirms, try to switch automatically to the other account
  //         if (response && response.actionMode === 'DELETE') {
  //           this.tryToSwitchAutomaticallyInOtherAccount(_account_detail);
  //         }
  //       }
  //     )
  //   )
  // }

  // Method to get parent details
  getParentDetails() {
    // Call the service to get the list of linked account users
    this.apiSubscription = this.userDataService.getLinkedAccountUsersList().subscribe(
      (response: any) => {
        // Store the linked accounts list
        this.linkedAccountsList = response;
        this.parentAccountsList = response.filter((_link_account) => _link_account.process.is_parent);
        this.studentAccountsList = response.filter((_link_account) => !_link_account.process.is_parent);
        // Set data loading to false
        this.dataLoading = false;
        // If the popup was triggered automatically and there is only one linked account
        // if (this.popupTriggeredBy === 'AUTOMATIC') {
        //   if (this.studentAccountsList.length === 1) {
        //     // If the linked account is not a parent account, try to switch automatically to the other account
        //     if (!this.studentAccountsList[0].process.is_parent) {
        //       this.accountSwitchActionObj = { do_automatic_switch_account: true, account_detail: this.studentAccountsList[0] };
        //       // this.tryToSwitchAutomaticallyInOtherAccount(this.studentAccountsList[0], this.popupTriggeredBy);
        //     }
        //   }
        // }
      },
      (error: any) => {
        // On error, set data loading to false and log the error
        this.dataLoading = false;
        console.error(error)
      }
    )
  }

  // Lifecycle hook that is called after Angular has initialized all data-bound properties
  ngOnInit() {
    // Get parent details when the component is initialized
    this.getParentDetails();
  }

  // Lifecycle hook that is called when the component is destroyed
  ngOnDestroy(): void {
    // Unsubscribe from the API subscription
    this.apiSubscription?.unsubscribe();
    // Unsubscribe from all other subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
