import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoLoginBoxComponent } from './auto-login-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WarningModernViewModalModule } from '@studentapp_shared/common-dialog/warning-modern-view-modal/warning-modern-view-modal.module';

@NgModule({
  declarations: [
    AutoLoginBoxComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    WarningModernViewModalModule,
  ],
  exports: [
    AutoLoginBoxComponent
  ]
})
export class AutoLoginBoxModule { }
