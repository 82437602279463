<a href="javascript:void(0);" class="pop-close" (click)="closeDialog()">
  <img src="assets/images/svg/close.svg" alt="">
</a>
<div class="parent-u-details" *ngIf="currentUserData">
  <div class="parent-u-details-bx">
    <!-- <span class="parent-u-nm"><strong>Parent Name: </strong>{{ currentUserData.username | titlecase }}</span> -->
    <span class="parent-u-info" *ngIf="currentUserData.is_parent">Close this popup to stay loggedin as parent.</span>
    <span class="parent-u-email"><strong>Logged in as {{ currentUserData.is_parent ? 'parent' : 'student' }}: </strong>{{ currentUserData.email ? currentUserData.email : currentUserData.username }}</span>
    <!-- <span class="parent-u-id">Account ID: #{{ currentUserData.id }}</span> -->
  </div>
</div>

<div class="all-ac-list-wrapper">
  <ng-scrollbar>
    <ng-container *ngIf="dataLoading">
      <app-progress-spinner class="loader-center-page negative-250 w-100" [msg]="'Please wait accounts are loading...'" [diameter]="50"></app-progress-spinner>
    </ng-container>
    <ng-container *ngIf="dataLoading === false && studentAccountsList.length === 0 && parentAccountsList.length === 0">
      <app-no-data-view-ui class="no-lv-course-data w-100" [NoDataText]="'No accounts are found'"></app-no-data-view-ui>
    </ng-container>
    
    <!-- PARENTS ACCOUNTS LISTING -->
    <ng-container *ngIf="dataLoading === false && parentAccountsList.length > 0">
      <h5>Parent Accounts</h5>
      <div class="all-ac-list no-overflow m-0">
        <ng-container *ngFor="let _each_account of parentAccountsList; let _account_index = index">
          <ng-container *ngIf="_each_account.process && _each_account.process.students && _each_account.process.students.length">
            <div class="ac-image-c with-check" [class.active]="selectedAccountCtrl.value === _each_account.id">
              <label class="u-photo" (click)="triggerSwitchAccountEvent(_each_account)">
                <!-- <input type="radio" [formControl]="selectedAccountCtrl" [value]="_each_account.id" name="u_list" (change)="showWarningDialogBeforeSwitchDifferentAccount(_each_account)" [disabled]="isDisabled"> -->
                <img src="assets/images/user.png" alt="" *ngIf="!(_each_account.process.students[0].avatar_link)">
                <img [src]="imagePath + _each_account.process.students[0].avatar_link" alt="" *ngIf="(_each_account.process.students[0].avatar_link)">
                <span class="change">
                  <img src="assets/images/svg/tick.svg" alt="">
                </span>
              </label>
              <span class="nm">{{ (_each_account.process.students[0].nick_name ? _each_account.process.students[0].nick_name : _each_account.process.students[0].name) | titlecase }}</span>
              <span class="s-id red">{{ _each_account.username }}</span>
              <span class="s-id d-block" *ngIf="_each_account.choice; else checkStudentLevelGenderForParent">{{ _each_account.choice | titlecase }}</span>
              <ng-template #checkStudentLevelGenderForParent>
                <span class="s-id d-block" *ngIf="_each_account.process.students[0].gender">{{ _each_account.process.students[0].gender == 'boy' ? 'Father' : _each_account.process.students[0].gender == 'girl' ? 'Mother' : '' }}</span>
              </ng-template>
              <!-- <span class="s-id d-block">Acc ID: #{{ _each_account.id }}</span>
              <span class="s-id">Stu ID: #{{ _each_account.process.students[0].id }}</span> -->
              <span class="subc" *ngIf="((_each_account.process.students[0]?.student_subscription | json) == '{}') || (_each_account.process.students[0]?.student_subscription?.status === 'cancelled')">
                (No Subscription)
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    
    <!-- STUDENTS ACCOUNTS LISTING -->
    <ng-container *ngIf="dataLoading === false && studentAccountsList.length > 0">
      <h5>Students/Children Accounts</h5>
      <div class="all-ac-list no-overflow m-0">
        <ng-container *ngFor="let _each_account of studentAccountsList; let _account_index = index">
          <ng-container *ngIf="_each_account.process && _each_account.process.students && _each_account.process.students.length">
            <div class="ac-image-c with-check" [class.active]="selectedAccountCtrl.value === _each_account.id">
              <label class="u-photo" (click)="triggerSwitchAccountEvent(_each_account)">
                <!-- <input type="radio" [formControl]="selectedAccountCtrl" [value]="_each_account.id" name="u_list" (change)="showWarningDialogBeforeSwitchDifferentAccount(_each_account)" [disabled]="isDisabled"> -->
                <img src="assets/images/user.png" alt="" *ngIf="!(_each_account.process.students[0].avatar_link)">
                <img [src]="imagePath + _each_account.process.students[0].avatar_link" alt="" *ngIf="(_each_account.process.students[0].avatar_link)">
                <span class="change">
                  <img src="assets/images/svg/tick.svg" alt="">
                </span>
              </label>
              <span class="nm">{{ (_each_account.process.students[0].nick_name ? _each_account.process.students[0].nick_name : _each_account.process.students[0].name) | titlecase }}</span>
              <span class="s-id red">{{ _each_account.username }}</span>
              <span class="s-id d-block" *ngIf="_each_account.process.students[0].gender">{{ _each_account.process.students[0].gender == 'boy' ? 'Boy' : _each_account.process.students[0].gender == 'girl' ? 'Girl' : '' }}</span>
              <!-- <span class="s-id d-block">Acc ID: #{{ _each_account.id }}</span>
              <span class="s-id">Stu ID: #{{ _each_account.process.students[0].id }}</span> -->
              <span class="subc" *ngIf="((_each_account.process.students[0]?.student_subscription | json) == '{}') || (_each_account.process.students[0]?.student_subscription?.status === 'cancelled')">
                (No Subscription)
              </span>
            </div>
          </ng-container>
        </ng-container>
      
        <ng-container *ngIf="dataLoading === false && studentAccountsList.length < 5">
          <div class="ac-image-c with-check" *ngIf="currentUserData.is_parent" (click)="addNewStudent()">
            <label class="u-photo">
              <span class="add-user">
                <img src="assets/images/svg/add-black.svg" alt="">
              </span>
            </label>
            <span class="nm">Add Student</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
</ng-scrollbar>
</div>

<app-auto-login-box [triggerActionData]="accountSwitchActionObj" (switchAccountEmitter)="handleAccountSwitchEventHandler($event)"></app-auto-login-box>
