import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
// import { BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE, TOP_MENU_FLAG_VALUE } from '@studentapp_core/common/constant';
import { MenuGlobalDataService } from '@studentapp_core/services/menu-global-data.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigLoaderResolver implements Resolve<boolean> {

  // bottomMenuFlagValue = BOTTOM_MENU_FLAG_VALUE;
  // leftMenuFlagValue = LEFT_MENU_FLAG_VALUE;
  // topMenuFlagValue = TOP_MENU_FLAG_VALUE;

  constructor(
    private menuGlobalDataService: MenuGlobalDataService
  ) { }

  getPageMenuConfigurationFromRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _menu_config_info = {
      page_menu_visibility: [],
    };

    let _current_page_menu_visibility = [];
    if (route.data.pageConfig && route.data.pageConfig.visibleMenus && route.data.pageConfig.visibleMenus.length) {
      _current_page_menu_visibility = route.data.pageConfig.visibleMenus;
    }
    _menu_config_info.page_menu_visibility = _current_page_menu_visibility;

    return _menu_config_info;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const _menu_config_info = this.getPageMenuConfigurationFromRoute(route, state);
    this.menuGlobalDataService.updateCurrentPageMenuSetting(_menu_config_info);
    console.log('_menu_config_info', _menu_config_info);
    // if (_menu_config_info.page_menu_visibility.indexOf(this.bottomMenuFlagValue) >= 0) {
    //   this.menuGlobalDataService.showMobilePageBottomMenu();
    // }
    // else {
    //   this.menuGlobalDataService.hideMobilePageBottomMenu();
    // }

    // if (_menu_config_info.page_menu_visibility.indexOf(this.topMenuFlagValue) >= 0 || _menu_config_info.page_menu_visibility.indexOf(this.leftMenuFlagValue) >= 0) {
    //   this.menuGlobalDataService.showPageGlobalTopAndLeftMenu();
    // }
    // else {
    //   this.menuGlobalDataService.hidePageGlobalTopAndLeftMenu();
    // }
    return of(true);
  }
}
